import React, { getGlobal } from "reactn";
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt, faCog } from "@fortawesome/free-solid-svg-icons";
import { Avatar, Menu, Dropdown, Button } from "antd";

import { authAPI } from "utils/portal-apis"; // TODO
import { SignIn } from "components/Auth";

export const defaultSettings = {
  logo: "/logo_luminary.png",
  navTheme: "light",
  layout: "topmenu",
  fixedHeader: true,
  contentWidth: "Fixed",
  title: "",
  style: {
    minHeight: "100vh"
  },
  menuItemRender: (menuItemProps, defaultDom) => {
    if (menuItemProps.path.indexOf("http") > -1) {
      return defaultDom;
    }
    return <Link to={menuItemProps.path}>{defaultDom}</Link>;
  }
};

export const publicDefaultSettings = {
  logo: "/logo_luminary.png", //Will have no effect, has been overwritten by the menuHeaderRender param in the prolayout
  navTheme: "light",
  layout: "topmenu",
  fixedHeader: true,
  contentWidth: "Fixed",
  title: "",
  style: {
    minHeight: "100vh"
  },
  menuItemRender: (menuItemProps, defaultDom) => {
    if (menuItemProps.path.indexOf("http") > -1) {
      return defaultDom;
    }
    return <Link to={menuItemProps.path}>{defaultDom}</Link>;
  }
};

export const getMenuRoutes = () => {
  const loggedInUser = getGlobal();

  return {
    path: "/",
    routes: [
      {
        path: "/broadcast/my",
        name: "Dashboard"
      },
      {
        path: `/dashboard/${loggedInUser.role}`,
        name: "Dashboard",
        hideInMenu: true
      },
      {
        name: "Producer Tools",
        hideInMenu: ["admin", "producer"].indexOf(loggedInUser.role) < 0 && !loggedInUser.can_manage_org,
        routes: [
          {
            path: "/event/active",
            name: "Active Events"
          },
          // {
          //   path: "/orgs/list",
          //   name: "Orgs"
          // },
          // {
          //   path: `/schedule/new`,
          //   name: "Add Event"
          // },
          {
            path: "/user/list",
            name: "Users"
          }
          // {
          //   path: "/broadcast/list",
          //   name: "Streams"
          // },
          // {
          //   path: "/schedule/guide",
          //   name: "Listings"
          // },
          // {
          //   path: "/schedule/channels",
          //   name: "Channels"
          // }
        ]
      },
      {
        path: "https://docs.hotmic.io/knowledge",
        name: "Help",
        target: "_blank"
      },
      {
        path: "/settings/:name",
        name: "Settings",
        hideInMenu: true
      },
      {
        path: "/event/:id",
        name: "Event Dashboard",
        hideInMenu: true
      },
      {
        path: "/broadcast/:id",
        name: "Broadcast Dashboard",
        hideInMenu: true
      }
    ]
  };
};

export function RightContent({ isPublicPage, showSignIn, signInSetter }) {
  const loggedInUser = getGlobal();

  const actionSelect = ({ key }) => {
    if (key === "logout") {
      authAPI.logout();
    }
  };

  const menuHeaderDropdown = (
    <Menu onClick={actionSelect}>
      {isPublicPage !== true && (
        <Menu.Item key="settings">
          <Link to="/settings">
            <FontAwesomeIcon icon={faCog} className="anticon" fixedWidth /> Settings
          </Link>
        </Menu.Item>
      )}
      {isPublicPage !== true && <Menu.Divider />}
      <Menu.Item key="logout">
        {isPublicPage !== true && (
          <Link
            to={{
              pathname: "/",
              search: window.location.search
            }}
          >
            <FontAwesomeIcon icon={faSignOutAlt} className="anticon" fixedWidth /> Logout
          </Link>
        )}
        {isPublicPage === true && (
          <Link
            to={{
              search: window.location.search
            }}
          >
            <FontAwesomeIcon icon={faSignOutAlt} className="anticon" fixedWidth /> Logout
          </Link>
        )}
      </Menu.Item>
    </Menu>
  );

  if (authAPI.isLoggedIn()) {
    return (
      <Dropdown overlay={menuHeaderDropdown}>
        <span style={{ height: "64px", display: "block", cursor: "pointer" }}>
          <Avatar
            src={
              loggedInUser.profile_pic || "https://hotmic-content.s3-us-west-1.amazonaws.com/users/default-profile.png"
            }
          />
          <span style={{ margin: "0 10px" }}>{loggedInUser.name}</span>
        </span>
      </Dropdown>
    );
  } else {
    return (
      <span>
        <Button type="primary" style={{ paddingLeft: 20, paddingRight: 20 }} onClick={() => signInSetter(true)}>
          Sign In
        </Button>
        <SignIn showModal={showSignIn} onCancelled={() => signInSetter(false)} />
      </span>
    );
  }
}
