// const portal_endpoint = "https://ec2-54-218-88-78.us-west-2.compute.amazonaws.com:3001";
// const portal_endpoint = "http://34.221.218.167:3001";
const portal_endpoint = "https://portal-api.hotmic.io";
// const portal_endpoint = "http://54.201.31.248:3001";
//const portal_endpoint = "http://34.213.136.88:3000";

export const fetchWrapperPortal = async ({ ...args }) => {
  const fetchObj = {};
  fetchObj.headers = args.headers || {};
  fetchObj.method = args.method.toUpperCase();
  fetchObj.body = args.body ? args.body : null;
  fetchObj.headers["Content-Type"] = "application/json";
  fetchObj.headers["ApiKey"] = process.env.REACT_APP_APP_KEY;
  if (args.addBearer) {
    fetchObj.headers["Authorization"] = args.token ? args.token : `Bearer ${localStorage.getItem("access_token")}`;
  }
  try {
    const result = await fetch(`${portal_endpoint}/${args.url}`, fetchObj);
    return result && result.status === 204 ? result.status : await result.json();
  } catch (e) {
    console.log(`HTTP ERROR: ${e}`, args);
  }
};
