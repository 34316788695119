const host = "https://api.hotmic.io";
//const host = "http://52.37.95.5:3000";

export const fetchWrapperPlatform = async ({ ...args }) => {
  const fetchObj = {};
  fetchObj.headers = args.headers || {};
  fetchObj.method = args.method.toUpperCase();
  fetchObj.body = args.body ? args.body : null;
  fetchObj.headers["Content-Type"] = "application/json";
  fetchObj.headers["ApiKey"] = process.env.REACT_APP_APP_KEY;
  if (args.addBearer) {
    fetchObj.headers["Authorization"] = args.token ? args.token : `Bearer ${localStorage.getItem("access_token")}`;
  }
  try {
    const result = await fetch(`${host}/${args.url}`, fetchObj);
    return result && result.status === 204 ? result.status : await result.json();
  } catch (e) {
    console.log(`HTTP ERROR: ${e}`, args);
  }
};
